input[type="search"] {
  border-radius: 0;
  border: 0 none;
  box-shadow: 0 3px 10px -2px rgba(0, 0, 0, 0.2);
}

input[type="search"]:hover,
input[type="search"]:focus,
input[type="search"]:active {
  border: 0 none;
  box-shadow: 0 3px 15px -2px rgba(0, 0, 0, 0.4);
}

.main-content {
  position: relative;
}

.main-content .devices-column {
  position: sticky;
  top: 0;
}

.devices-container {
  position: relative;
}

.reset-button {
  box-shadow: 0 -3px 15px -2px rgba(0, 0, 0, 0.3);
  position: sticky;
  bottom: 0;
  z-index: 1;
  width: 100%;
  transition: all 0.3s ease-in-out;
}

.membership-button{
  box-shadow: 0 -3px 15px -2px rgba(0, 0, 0, 0.3);
  position: sticky;
}

.tag {
  cursor: default;
}

.footer {
  box-shadow: 0 -3px 15px -2px rgba(0, 0, 0, 0.3);
}

.scroll-container {
  height: 1300px;
  overflow: scroll;
  overflow-x: hidden;
  scrollbar-width: none;
}